<template>
	<div class="check-detail">
		<mallHeader />
		<div class="mall-check-detail container">
			<ul class="breadcrumb">
				<li>
					<a href="#" @click="$router.push('/ori-mall')"
						><i class="el-icon-s-home"></i
					></a>
				</li>
				<li><i class="el-icon-arrow-right"></i></li>
				<li><a href="#">查看详情</a></li>
			</ul>
			<div class="detail-action-page">
				<div class="detail-action">
					<div class="state">
						<img src="@/assets/images/icon/money.png" alt="" />
						<p>已取消</p>
					</div>
					<div class="info">
						<p>订单编号：1514150705298579457</p>
						<p>下单时间：2022-04-13 15:57:14</p>
					</div>
				</div>
				<div class="detail-steps">
					<el-steps :active="active" finish-status="success">
						<el-step title="提交订单"></el-step>
						<el-step title="付款成功"></el-step>
						<el-step title="商品发货"></el-step>
						<el-step title="确认收货"></el-step>
						<el-step title="订单完成"></el-step>
					</el-steps>
					<div class="detail-steps-time">
						2022.02.22 14:22:22
					</div>
				</div>
				<div class="order-info">
					<h3>订单商品</h3>
					<table class="goods-table">
						<thead>
							<tr>
								<th width="400">商品信息</th>
								<th>单价</th>
								<th>数量</th>
								<th>小计</th>
								<th>实付</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<a href="javascript:;" class="product"
										><img src="@/assets/images/b1.png" alt="" />
										<div class="info">
											<p class="name ellipsis">
												全防水HABU旋钮牛皮户外徒步鞋山宁泰抗菌
											</p>
											<p class="attrs ellipsis">
												颜色:骑士黑 尺码:39
											</p>
										</div></a
									>
								</td>
								<td>¥444</td>
								<td>1</td>
								<td>¥444</td>
								<td>¥444</td>
							</tr>
						</tbody>
					</table>
					<h3>收货信息</h3>
					<div class="info-box">
						<dl>
							<dt>收<i></i>货<i></i>人：</dt>
							<dd>1</dd>
						</dl>
						<dl>
							<dt>联系方式：</dt>
							<dd>17607926502</dd>
						</dl>
						<dl>
							<dt>收货地址：</dt>
							<dd>上海上海市静安区12312</dd>
						</dl>
					</div>
					<h3>支付方式及送货时间</h3>
					<div class="info-box">
						<dl>
							<dt>支付方式:</dt>
							<dd>在线支付</dd>
						</dl>
						<dl>
							<dt>送货时间：</dt>
							<dd>双休日、假日送货(适合于家庭地址)</dd>
						</dl>
					</div>
					<h3>发票信息</h3>
					<div class="info-box">
						<dl>
							<dt>发票类型：</dt>
							<dd>电子发票</dd>
						</dl>
						<dl>
							<dt>发票内容：</dt>
							<dd>日用品</dd>
						</dl>
						<dl>
							<dt>发票抬头：</dt>
							<dd>个人</dd>
						</dl>
					</div>
					<h3>订单信息</h3>
					<div class="info-box">
						<dl>
							<dt>订单编号：</dt>
							<dd>145191251890519</dd>
						</dl>
						<dl>
							<dt>下单时间：</dt>
							<dd>2022-04-13 15:57:14</dd>
						</dl>
					</div>
					<div class="sum-info">
						<div class="info">
							<div class="item"><label>商品件数：</label><span>1件</span></div>
							<div class="item"><label>商品总价：</label><span>¥444</span></div>
							<div class="item">
								<label>运<i></i>费：</label><span>2</span>
							</div>
							<div class="item">
								<label>应付总额：</label><span class="price">¥446</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mallHeader from './header.vue'
export default {
	components: {
		mallHeader,
	},
	data() {
		return {
			active: 0,
		}
	},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.check-detail {
	color: #666;
	font-family: fangsong;
	background-color: #f0f3ef;
	li {
		list-style: none;
	}
	.container {
		width: 1200px;
		margin: 0 auto;
	}
	.mall-check-detail {
		.breadcrumb {
			margin: 23px 0;
			background-color: transparent;
			padding: 0;
			display: inline-block;
			width: 100%;
			line-height: 100%;
			li {
				display: inline-block;
				padding-left: 8px;
				margin-right: 7px;
				position: relative;
				a {
					display: block;
					padding: 0;
					color: #999;
					text-decoration: none;
					font-size: 14px;
				}
				a:hover {
					color: #005ea6;
				}
				i {
					font-size: 14px;
				}
			}
		}
		.detail-action-page {
			background-color: #fff;
			.detail-action {
				height: 180px;
				width: 100%;
				display: flex;
				align-items: center;
				.state {
					width: 320px;
					text-align: center;
				}
				.info {
					width: 340px;
					line-height: 30px;
					p {
						color: #999;
						margin: 0;
					}
				}
			}
			.detail-steps {
				margin-bottom: 20px;
				.el-steps {
					padding: 0 135px;
				}
				::v-deep {
					.el-step.is-horizontal .el-step__line {
						top: 26px;
					}
					.el-step__icon {
						width: 50px;
						height: 50px;
					}
					.el-step__head.is-process {
						border-color: #005ea6;
					}
					.el-step__icon-inner {
						font-size: 20px;
					}
				}
				.detail-steps-time {
					font-size: 14px;
					padding-left: 100px;
				}
			}
			.order-info {
				padding: 0 100px;
				h3 {
					padding: 25px 0;
					font-size: 18px;
					margin: 0;
				}
				.goods-table {
					width: 100%;
					border-collapse: collapse;
					border-spacing: 0;
					margin: 0 auto;
					th:first-child {
						text-align: left;
						padding-left: 20px;
					}
					th {
						font-weight: normal;
						line-height: 60px;
						background: #f5f5f5;
						text-align: center;
					}
					td:first-child {
						padding-left: 20px;
						border-left: 1px solid #f5f5f5;
					}
					td {
						border-bottom: 1px solid #f5f5f5;
						text-align: center;
					}
					td:last-child {
						border-right: 1px solid #f5f5f5;
					}
					.product {
						display: flex;
						padding: 20px 0;
						img {
							width: 70px;
							height: 70px;
							border: 1px solid #f5f5f5;
						}
						.info {
							align-self: center;
							padding-left: 20px;
							text-align: left;
							p {
								margin-bottom: 5px;
								width: 280px;
								font-size: 14px;
							}
							.attrs {
								color: #999;
							}
						}
					}
				}
				.info-box {
					border: 1px solid #f5f5f5;
					padding: 20px;
					dl {
						display: flex;
						line-height: 30px;
						margin: 0;
						dt {
							width: 80px;
							color: #999;
							font-size: 14px;
						}
						dd {
							flex: 1;
						}
					}
				}
				.sum-info {
					padding: 30px 30px 100px 30px;
					overflow: hidden;
					.info {
						width: 250px;
						height: 160px;
						float: right;
						font-size: 16px;
						.item {
							line-height: 40px;
							display: flex;
							label {
								width: 90px;
							}
							span {
								flex: 1;
								text-align: right;
								padding-right: 30px;
							}
						}
					}
				}
			}
		}
	}
}
</style>
